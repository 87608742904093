<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="filterByCompanyId"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
			:actions="actions"
		></pui-datatable>
		<resourcerequestmodals :modelName="modelName"></resourcerequestmodals>
		<customfields-modals></customfields-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import ResourcerequestModals from './ResourcerequestModals.vue';
import ResourcerequestActions from './ResourcerequestActions.js';

export default {
	name: 'resourcerequest-grid',
	components: { resourcerequestmodals: ResourcerequestModals },
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'resourcerequest',
			actions: ResourcerequestActions.gridactions,
			modelColumnDefs: {
				statusname: {
					render: (data, type, row) => {
						if (row.statusoutlinecolor && row.statuscolor) {
							return `<label style="color: white; background-color: ${row.statuscolor}; border: 2px solid ${row.statusoutlinecolor}; font-weight: bold; padding: 3px; border-radius: 10px">${data}</label>`;
						} else {
							return data;
						}
					}
				},
				isoperation: {
					render: (data, type, row) => {
						return row.isoperation ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	},
	computed: {
		filterByCompanyId() {
			const userCompanyId = this.$store.state.session.properties.COMPANY_ID;
			const userCompanyType = this.$store.state.session.properties.COMPANY.companytypeid;
			const consigneeType = 5;
			if (userCompanyId) {
				if (userCompanyType && userCompanyType === consigneeType) {
					return {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'company_id', op: 'eq', data: userCompanyId }]
					};
				} else {
					const userPorts = this.$store.getters.getPortsIds;
					return {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'portid', op: 'in', data: userPorts }]
					};
				}
			}
		}
	}
};
</script>
